<script setup>
    import { getOrCreateProcedure, getOrCreateDataObject } from 'o365.vue.ts';
    import { ref, watch } from 'vue';
    import IFrame from 'o365.vue.components.Iframe.vue';

    /* VARIABLES */
    const procGetBOJson = getOrCreateProcedure({ id: "procGetBOJson", procedureName: "astp_Lease_GetRoomsBIMObjectsJson" });
    const vIframe = ref(null);
    const vLoaded = ref(false);
    const vGroupColors = ref(null);
    const props = defineProps({ vModelID: Number,
                                vFieldName: {type: String, default: null},
                                vFilter: String,
                                vSelectedObjectIDs: {type: String, default: '[]'},
                                vVisMode: {type: String, default: 'default'},
                                vMapMode: {type: Boolean, default: false}
    });
    const emit = defineEmits(['groupColorsAdded'])
    let dsModel = null;
    let dsBF = null;
    let vJsonData = null;
    let vFragments = null;
    let vIfcObjectIDs = null;
    window["vSelectedObjectIDs"] = props.vSelectedObjectIDs;
    window["dsModel"] = dsModel;
    window["dsBF"] = dsBF;
    window["props"] = props;
    window["vGroupColors"] = vGroupColors;
    window["vJsonData"] = vJsonData;
    window["vFragments"] = vFragments;
    window["vIfcObjectIDs"] = vIfcObjectIDs;

    window.addEventListener('message', (pObj)=>{
        if (pObj.data.event == "onReady"){
            vLoaded.value = true;
            loadDataObjectsAndBimData();
        }
    });

    async function loadDataObjectsAndBimData() {
        await loadDataObjects();
        loadBimData();
    }

    /* WATCH */
    watch(() => props.vModelID, (first, second) => {
        console.log("Watch props.vModelID function called with args:", first, second);
        dsModel ? dsModel.load() : loadDataObjects();
    });

    watch(() => props.vFieldName, (first, second) => {
        console.log("Watch props.vFieldName function called with args:", first, second);
        loadBimData();
    });

    watch(() => props.vFilter, (first, second) => {
        console.log("Watch props.vFilter function called with args:", first, second);
        loadBimData();
    });

    watch(() => props.vSelectedObjectIDs, (first, second) => {
        console.log("Watch props.vSelectedObjectIDs function called with args:", first, second);
        loadBimData();
        window["vSelectedObjectIDs"] = props.vSelectedObjectIDs;
    });

    watch(() => props.vVisMode, (first, second) => {
        console.log("Watch props.vVisMode function called with args:", first, second);
        loadBimData();
    });

    watch(() => props.vMapMode, (first, second) => {
        console.log("Watch props.vMapMode function called with args:", first, second);
        loadBimData();
    });

    /* FUNCTIONS */
    async function loadDataObjects() {
        dsModel = props.vModelID ? getOrCreateDataObject({
            id: 'dsModel' + "_" + props.vModelID,
            viewName: "aviw_BIM_Models",
            maxRecords: 1,
            loadRecents: false,
            selectFirstRowOnLoad: true,
            whereClause: props.vModelID ? `ID = ${props.vModelID}` : "1=0",
            fields: [{ name: "ID" }],
        }) : null;

        dsBF = props.vModelID ? getOrCreateDataObject({
            id: 'dsBF' + "_" + crypto.randomUUID(),
            viewName: "aviw_Lease_BIMFragments",
            maxRecords: -1,
            loadRecents: false,
            selectFirstRowOnLoad: true,
            masterDataObject_ID: `dsModel_${props.vModelID}`,
            masterDetailDefinition: [{"masterField": "StoreyFragmentDef_ID", "detailField": "FragmentDef_ID", "operator": "equals"}],
            fields: [{ name: "ID" }],
        }) : null;

        await dsModel.load();
        window["dsModel"] = dsModel;
        window["dsBF"] = dsBF;
    }

    function transformJson(objectsArr) {
        const floors = {};
        vGroupColors.value = null;
        objectsArr.forEach((obj) => {
            const { Name : name, BIMObject_ID : bimObjectId, IFCFile_ID : ifcFileId, R : red, G : green, B : blue } = obj;
            if(!floors[name]) {
                floors[name] = {name: name, color: { r: red, g: green, b: blue }, objects: {}};
                vGroupColors.value = (vGroupColors.value ? vGroupColors.value + " | " : "") + "<span class='ml-2' style='color:rgb(" + red + "," + green + "," + blue + ");'>" + name + "</span>";
            }
            floors[name].objects[ifcFileId] ? floors[name].objects[ifcFileId].push(bimObjectId) : floors[name].objects[ifcFileId] = [ bimObjectId ];
        });
        return Object.values(floors);
    }

    async function loadBimData() {
        if(vLoaded.value && dsModel.current.ID) {
            const vData = await procGetBOJson.execute({
                                                        FieldName: props.vFieldName, 
                                                        Filter: props.vFilter,
                                                        Model_ID: props.vModelID, 
                                                        SpecifiedObjectJson: props.vSelectedObjectIDs
                                                    });
            if(vData.Table.length > 0) {
                vJsonData = JSON.parse(vData.Table[0]['JsonData']);
                vFragments = await dsBF.getData().map(function(row) { return row.ID});
                vIfcObjectIDs = vJsonData ? transformJson(vJsonData) : null;
                window["vJsonData"] = vJsonData;
                window["vFragments"] = vFragments;
                window["vIfcObjectIDs"] = vIfcObjectIDs;

                emit('groupColorsAdded', vGroupColors.value);
                
                vIframe.value.iframeRef.contentWindow.postMessage({
                    origin: vIframe.value.iframeRef.contentWindow.location.origin, 
                    channel_id: 'room_register',
                    event: 'loadViewerStateRequest', 
                    content: {
                                    fragments: vFragments,
                                    ifcobject_ids: vIfcObjectIDs,
                                    rule: {
                                        hardSelect: true,
                                        isolate: props.vVisMode == 'isolated' ? true : false,
                                        base_color: true,
                                        map_mode: props.vMapMode
                                    }
                                }
                });
            }
        }
    }
</script>

<template>
    <IFrame :src="'/bimviewer-rooms-fcm?HideNav=true&Model='+props.vModelID" ref="vIframe" style="height:100%;width:100%;"/> 
</template>